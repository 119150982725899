// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awards-media-bangalore-mirror-july-2014-js": () => import("./../../../src/pages/awards-&-media/bangalore-mirror-july-2014.js" /* webpackChunkName: "component---src-pages-awards-media-bangalore-mirror-july-2014-js" */),
  "component---src-pages-awards-media-bob-magazine-169th-edition-js": () => import("./../../../src/pages/awards-&-media/bob-magazine-169th-edition.js" /* webpackChunkName: "component---src-pages-awards-media-bob-magazine-169th-edition-js" */),
  "component---src-pages-awards-media-deccan-chronicle-may-10-2019-js": () => import("./../../../src/pages/awards-&-media/deccan-chronicle-may-10-2019.js" /* webpackChunkName: "component---src-pages-awards-media-deccan-chronicle-may-10-2019-js" */),
  "component---src-pages-awards-media-deccan-herald-july-11-2014-js": () => import("./../../../src/pages/awards-&-media/deccan-herald-july-11-2014.js" /* webpackChunkName: "component---src-pages-awards-media-deccan-herald-july-11-2014-js" */),
  "component---src-pages-awards-media-index-js": () => import("./../../../src/pages/awards-&-media/index.js" /* webpackChunkName: "component---src-pages-awards-media-index-js" */),
  "component---src-pages-awards-media-society-interiors-august-2014-js": () => import("./../../../src/pages/awards-&-media/society-interiors-august-2014.js" /* webpackChunkName: "component---src-pages-awards-media-society-interiors-august-2014-js" */),
  "component---src-pages-awards-media-sunday-times-of-india-june-15-2014-js": () => import("./../../../src/pages/awards-&-media/sunday-times-of-india-june-15-2014.js" /* webpackChunkName: "component---src-pages-awards-media-sunday-times-of-india-june-15-2014-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-bars-lounges-badmaash-js": () => import("./../../../src/pages/projects/bars-&-lounges/badmaash.js" /* webpackChunkName: "component---src-pages-projects-bars-lounges-badmaash-js" */),
  "component---src-pages-projects-bars-lounges-hangover-js": () => import("./../../../src/pages/projects/bars-&-lounges/hangover.js" /* webpackChunkName: "component---src-pages-projects-bars-lounges-hangover-js" */),
  "component---src-pages-projects-bars-lounges-hard-rock-cafe-js": () => import("./../../../src/pages/projects/bars-&-lounges/hard-rock-cafe.js" /* webpackChunkName: "component---src-pages-projects-bars-lounges-hard-rock-cafe-js" */),
  "component---src-pages-projects-bars-lounges-index-js": () => import("./../../../src/pages/projects/bars-&-lounges/index.js" /* webpackChunkName: "component---src-pages-projects-bars-lounges-index-js" */),
  "component---src-pages-projects-bars-lounges-mirage-js": () => import("./../../../src/pages/projects/bars-&-lounges/mirage.js" /* webpackChunkName: "component---src-pages-projects-bars-lounges-mirage-js" */),
  "component---src-pages-projects-entertainment-index-js": () => import("./../../../src/pages/projects/entertainment/index.js" /* webpackChunkName: "component---src-pages-projects-entertainment-index-js" */),
  "component---src-pages-projects-hospitality-index-js": () => import("./../../../src/pages/projects/hospitality/index.js" /* webpackChunkName: "component---src-pages-projects-hospitality-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-microbreweries-taprooms-index-js": () => import("./../../../src/pages/projects/microbreweries-&-taprooms/index.js" /* webpackChunkName: "component---src-pages-projects-microbreweries-taprooms-index-js" */),
  "component---src-pages-projects-microbreweries-taprooms-sample-js": () => import("./../../../src/pages/projects/microbreweries-&-taprooms/sample.js" /* webpackChunkName: "component---src-pages-projects-microbreweries-taprooms-sample-js" */),
  "component---src-pages-projects-microbreweries-taprooms-the-pump-house-js": () => import("./../../../src/pages/projects/microbreweries-&-taprooms/the-pump-house.js" /* webpackChunkName: "component---src-pages-projects-microbreweries-taprooms-the-pump-house-js" */),
  "component---src-pages-projects-microbreweries-taprooms-the-reservoire-js": () => import("./../../../src/pages/projects/microbreweries-&-taprooms/the-reservoire.js" /* webpackChunkName: "component---src-pages-projects-microbreweries-taprooms-the-reservoire-js" */),
  "component---src-pages-projects-microbreweries-taprooms-xoox-brewmill-js": () => import("./../../../src/pages/projects/microbreweries-&-taprooms/xoox-brewmill.js" /* webpackChunkName: "component---src-pages-projects-microbreweries-taprooms-xoox-brewmill-js" */),
  "component---src-pages-projects-restaurants-eateries-cantan-js": () => import("./../../../src/pages/projects/restaurants-&-eateries/cantan.js" /* webpackChunkName: "component---src-pages-projects-restaurants-eateries-cantan-js" */),
  "component---src-pages-projects-restaurants-eateries-index-js": () => import("./../../../src/pages/projects/restaurants-&-eateries/index.js" /* webpackChunkName: "component---src-pages-projects-restaurants-eateries-index-js" */),
  "component---src-pages-projects-restaurants-eateries-maffys-js": () => import("./../../../src/pages/projects/restaurants-&-eateries/maffy’s.js" /* webpackChunkName: "component---src-pages-projects-restaurants-eateries-maffys-js" */),
  "component---src-pages-projects-restaurants-eateries-the-fatty-bao-js": () => import("./../../../src/pages/projects/restaurants-&-eateries/the-fatty-bao.js" /* webpackChunkName: "component---src-pages-projects-restaurants-eateries-the-fatty-bao-js" */),
  "component---src-pages-projects-retail-index-js": () => import("./../../../src/pages/projects/retail/index.js" /* webpackChunkName: "component---src-pages-projects-retail-index-js" */),
  "component---src-pages-projects-workspaces-index-js": () => import("./../../../src/pages/projects/workspaces/index.js" /* webpackChunkName: "component---src-pages-projects-workspaces-index-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

